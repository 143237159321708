<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        class="mx-auto"
        min-width="300"
        max-width="380"
      >
        <v-img
          :src="thumbNail ? thumbNail : require('@/assets/cover_pcb.jpg')"
          :aspect-ratio="16 / 9"
          cover
          height="150"
        ></v-img>
        <v-card-title
          v-if="project.project_name.length > 0"
          class="mr-5 title text--primary text-truncate"
        >
          {{ project.project_name }}
        </v-card-title>

        <v-card-subtitle class="body-1 text--primary mb-2">
          <v-row dense>
            <v-col cols="4">
              <div>{{ partNumber }}</div>
            </v-col>
            <v-col cols="8" class="d-flex justify-end primary--text">
              {{ fixtureStateName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="9">
              <span class="caption">
                <div class="text--primary">
                  Created: {{ dateFormatter(project.created) }}
                </div>
                <div class="text--primary">
                  Last modified: {{ dateFormatter(project.modified) }}
                </div>
                <div class="text--primary">
                  User: {{ project.user_name }} - {{ project.user_email }}
                </div>
                <div class="text--primary">
                  <span v-if="project.company_name || project.company_request">
                    Company: {{ project.company_name }}
                    <span v-if="project.company_name && project.company_request"
                      >|</span
                    >
                    {{ project.company_request }}
                  </span>
                  <span v-else>.</span>
                </div>
              </span>
            </v-col>
            <v-col cols="3">
              <v-row dense>
                <v-col cols="12" class="d-flex justify-end">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        small
                        color="primary"
                        v-on="on"
                        @click="
                          gotoProjectFlow({
                            pathName: 'project-edit',
                            pathId: project.pk,
                            activeTab: 0,
                            newtab: false,
                          })
                        "
                        @contextmenu="
                          activateMenu({
                            nextRoute: {
                              pathName: 'project-edit',
                              pathId: project.pk,
                              activeTab: 0,
                              newtab: true,
                            },
                            nextAction: 'gotoProjectFlow',
                            event: $event
                          })
                        "
                        ><v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Project Configuration</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          text
                          small
                          color="primary"
                          @click.stop="
                            showDialog({
                              id: project.pk,
                              nextAction: 'delete',
                              message: `Are you sure to delete Project no. ${project.pk}?`,
                            })
                          "
                          :disabled="!isInitialState && !isAdmin"
                          :data-cy="`fd-project-delete-button-${project.pk}`"
                          ><v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>{{
                      isInitialState || isAdmin
                        ? "Delete Project"
                        : "Project is no longer in Initial Design, only admins can delete it"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" class="d-flex justify-end">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn
                          small
                          color="secondary"
                          class="px-5"
                          :disabled="
                            isDCCount >= maxDuts ||
                              (!isInitialState && !isAdmin)
                          "
                          @click="
                            gotoProjectFlow({
                              pathName: 'project-edit',
                              pathId: project.pk,
                              activeTab: 1,
                              newtab: false,
                            })
                          "
                          @contextmenu="
                            activateMenu({
                              nextRoute: {
                                pathName: 'project-edit',
                                pathId: project.pk,
                                activeTab: 1,
                                newtab: true,
                              },
                              nextAction: 'gotoProjectFlow',
                              event: $event
                            })
                          "
                          >Add DUT
                        </v-btn>
                      </span>
                    </template>
                    <span>{{
                      isDCCount >= maxDuts || (!isInitialState && !isAdmin)
                        ? "Max two DUTs or Project no longer in initial design state"
                        : "Add New DUT"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-sheet outlined rounded class="px-4 pb-2 pt-5">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-select
                  v-if="isDCCount > 0"
                  :items="dutConfigsList"
                  label="DUT Configs"
                  item-text="name"
                  item-value="pk"
                  v-model="selectedDut"
                  clearable
                  dense
                  hide-details
                  class="caption"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.pk }} - {{ data.item.name }}
                  </template>
                </v-select>
                <v-layout v-else class="px-3 py-4 my-1" row>
                  <span
                    class="body-1 text--primary text-truncate font-weight-bold"
                    >No DUTs configured for this fixture</span
                  >
                </v-layout>
              </v-col>
            </v-row>
            <v-row dense v-if="isDCCount > 0">
              <v-col cols="3" class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      @click="dutCSVHandler(selectedDut)"
                      v-on="on"
                      :disabled="!selectedDut"
                      >mdi-download</v-icon
                    >
                  </template>
                  <span>Export Selected DUT to CSV</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      v-on="on"
                      class="px-1 ml-1"
                      :disabled="!selectedDut"
                      @click="
                        gotoProjectFlow({
                          pathName: 'project-edit',
                          pathId: project.pk,
                          activeTab: getIndexSelectedDut() + 2,
                          newtab: false,
                        })
                      "
                      @contextmenu="
                        activateMenu({
                          nextRoute: {
                            pathName: 'project-edit',
                            pathId: project.pk,
                            activeTab: getIndexSelectedDut() + 2,
                            newtab: true,
                          },
                          nextAction: 'gotoProjectFlow',
                          event: $event
                        })
                      "
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>DUT Configuration</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      v-on="on"
                      class="px-1"
                      :disabled="!selectedDut"
                      :data-cy="`fd-project-configure-button-${project.pk}`"
                      @click="
                        gotoConfigure({
                          pathName: 'configure',
                          projectId: project.pk,
                          dutId: selectedDut,
                          newtab: false,
                        })
                      "
                      @contextmenu="
                        activateMenu({
                          nextRoute: {
                            pathName: 'configure',
                            projectId: project.pk,
                            dutId: selectedDut,
                            newtab: true,
                          },
                          nextAction: 'gotoConfigure',
                          event: $event
                        })
                      "
                      >mdi-cog</v-icon
                    >
                  </template>
                  <span
                    >Test Points, Guide Pins, and Pressure Pins
                    Configuration</span
                  >
                </v-tooltip>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon
                        color="primary"
                        @click.stop="
                          showDialog({
                            nextAction: 'remove',
                            message: `Are you sure to remove DUT no. ${selectedDut}?`,
                          })
                        "
                        class="px-1"
                        :disabled="!selectedDut || !isInitialState"
                        >mdi-delete</v-icon
                      ></span
                    >
                  </template>
                  <span>Delete Selected DUT</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
        <v-divider />
        <v-row class="mt-1 mx-2">
          <v-col class="d-flex justify-space-around">
            <v-tooltip top v-if="isAdmin">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    text
                    small
                    :color="quotePk ? 'warning' : 'primary'"
                    @click="gotoQuotesLink"
                    @contextmenu="
                      activateMenu({
                        nextRoute: {
                          link: 'quotes',
                        },
                        nextAction: 'showUnlinkDialog',
                        event: $event,
                        buttonText: 'Unlink From Quote'
                      })
                    "
                    ><v-icon>mdi-paper-roll</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>{{ quotePk ? "Quote Details" : "Link to Quote" }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  color="primary"
                  v-on="on"
                  v-show="isDCCount > 0"
                  :data-cy="`fd-project-render-button-${project.pk}`"
                  @click="
                    gotoNextRoute({
                      pathName: 'render',
                      pathId: project.pk,
                      newtab: false,
                    })
                  "
                  @contextmenu="
                    activateMenu({
                      nextRoute: {
                        pathName: 'render',
                        pathId: project.pk,
                        newtab: true,
                      },
                      nextAction: 'gotoNextRoute',
                      event: $event
                    })
                  "
                  ><v-icon>mdi-rotate-3d</v-icon>
                </v-btn>
              </template>
              <span>3D Render Configuration</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  color="primary"
                  v-on="on"
                  v-show="isFDavailable && isDCCount > 0"
                  @click="
                    gotoNextRoute({
                      pathName: 'fulfillment',
                      pathId: project.pk,
                      newtab: false,
                    })
                  "
                  @contextmenu="
                    activateMenu({
                      nextRoute: {
                        pathName: 'fulfillment',
                        pathId: project.pk,
                        newtab: true,
                      },
                      nextAction: 'gotoNextRoute',
                      event: $event
                    })
                  "
                  ><v-icon>mdi-auto-fix</v-icon>
                </v-btn>
              </template>
              <span>Fixture Fulfillment</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  color="warning"
                  v-on="on"
                  v-show="isAdmin"
                  @click="
                    gotoNextRoute({
                      pathName: 'design-packages',
                      pathId: project.pk,
                      newtab: false,
                    })
                  "
                  @contextmenu="
                    activateMenu({
                      nextRoute: {
                        pathName: 'design-packages',
                        pathId: project.pk,
                        newtab: true,
                      },
                      nextAction: 'gotoNextRoute',
                      event: $event
                    })
                  "
                  ><v-icon>mdi-package-variant</v-icon>
                </v-btn>
              </template>
              <span>Design File Package</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  color="primary"
                  v-on="on"
                  v-show="isAdmin"
                  @click="
                    gotoSvgDrawing({
                      projectPk: project.pk,
                      fdId: fixtureDesignId,
                      dutId: selectedDut,
                      newtab: false,
                    })
                  "
                  @contextmenu="
                    activateMenu({
                      nextRoute: {
                        projectPk: project.pk,
                        fdId: fixtureDesignId,
                        dutId: selectedDut,
                        newtab: true,
                      },
                      nextAction: 'gotoSvgDrawing',
                      event: $event
                    })
                  "
                  ><v-icon>mdi-drawing</v-icon>
                </v-btn>
              </template>
              <span>SVG Drawing</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div v-if="isAdmin">
          <v-row v-if="!tpcbConfigId" dense class="ma-3">
            <v-col class="d-flex justify-center mb-1">
              <tpcb-config-modal
                :projectName="project.project_name"
                @saveData="onSaveTPCB"
              />
            </v-col>
          </v-row>
          <v-row v-else dense class="ma-3">
            <v-col class="d-flex justify-center mb-1">
              <v-btn
                text
                color="warning"
                @click="$router.push('/tpcb/project/' + project.pk)"
                >View TPCB</v-btn
              >
              <v-spacer />
              <v-btn
                text
                color="warning"
                @click="
                  showDialog({
                    nextAction: 'discard',
                    message: `Are you sure to discard TPCB for project ${project.project_name}?`,
                  })
                "
                >Delete TPCB</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-hover>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @delete="deleteHandler"
      @remove="dutDeleteHandler"
      @discard="tpcbDeleteHandler"
      @saveQuote="onSaveQuote"
      @unlinkProject="unlinkFromQuote"
      @donothing="stopDialog"
    />
    <BaseSubMenuPopup
      :showMenu="showMenu"
      :xCoord="xCoord"
      :yCoord="yCoord"
      :nextAction="nextAction"
      :buttonText="buttonText"
      @onClick="subMenuClickHandler"
      @onCopyUrl="subMenuCopyUrl"
    />
    <SearchQuoteModal
      :dialog="quoteDialog"
      :fixtureDesignId="fixtureDesignId"
      @saveQuote="showQuoteDialog"
      @closeQuoteModal="onCloseQuoteModal"
    />
  </div>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import SubMenuPopup from "@/mixins/SubMenuPopup";
import { csvExporter } from "@/helpers/basehelper.js";
import { mapActions } from "vuex";
export default {
  name: "ProjectItem",
  mixins: [BottomDialog, SubMenuPopup],
  props: {
    project: {
      type: Object,
      default: null,
    },
    defaultDut: {
      type: String,
      default: null,
    },
    fdState: {
      type: Array,
      default: null,
    },
    numberOfDuts: {
      type: Number,
      default: null,
    },
  },
  components: {
    TpcbConfigModal: () =>
      import("@/modules/tpcb/components/TpcbConfigModal.vue"),
    SearchQuoteModal: () => import("./SearchQuoteModal.vue"),
  },
  data() {
    return {
      selectedDut: this.defaultDut,
      maxDuts: 2,
      quoteDialog: false,
      quoteId: null,
      DEFAULT_PCB_THICKNESS: 1.6,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    isFDavailable() {
      return Object.keys(this.project.fixture_design_details).length > 0;
    },
    thumbNail() {
      return this.isFDavailable
        ? this.project.fixture_design_details[
            Object.keys(this.project.fixture_design_details)[0]
          ].thumbnail
        : null;
    },
    quotePk() {
      return this.isFDavailable
        ? this.project.fixture_design_details[
            Object.keys(this.project.fixture_design_details)[0]
          ].fd_quote_pk
        : null;
    },
    partNumber() {
      return this.isFDavailable
        ? this.project.fixture_design_details[
            Object.keys(this.project.fixture_design_details)[0]
          ].full_part_number
        : null;
    },
    fixtureState() {
      return this.isFDavailable
        ? this.project.fixture_design_details[
            Object.keys(this.project.fixture_design_details)[0]
          ].state
        : null;
    },
    isInitialState() {
      return this.isFDavailable
        ? this.project.fixture_design_details[
            Object.keys(this.project.fixture_design_details)[0]
          ].state === 1
        : true;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureState
      );
      return result ? result.description : "";
    },
    fixtureDesignDetails() {
      return this.project.fixture_design_details;
    },
    fixtureDesignId() {
      return Object.keys(this.project.fixture_design_details).length > 0
        ? Object.keys(this.project.fixture_design_details)[0]
        : null;
    },
    isDCCount() {
      return Object.keys(this.project.dut_config_details).length;
    },
    dutConfigsList() {
      return this.isDCCount > 0
        ? Object.entries(this.project.dut_config_details).map(([k, v]) => ({
            pk: k,
            name: v[Object.keys(v)],
          }))
        : null;
    },
    testpointLayers() {
      return this.$store.getters["dutconfigs/testpointLayers"];
    },
    testpointTypes() {
      return this.$store.getters["dutconfigs/testpointTypes"];
    },
    guidepinLayers() {
      return this.$store.getters["dutconfigs/guidepinLayers"];
    },
    testPoints() {
      return this.$store.getters["dutconfigs/dutConfigs"].testpoints
        ? this.$store.getters["dutconfigs/dutConfigs"].testpoints.map(
            (element) => {
              const tpLayer = this.testpointLayers.find(
                (layer) => layer.pk == element.layer
              );
              const tpType = this.testpointTypes.find(
                (type) => type.pk == element.type
              );
              return {
                Net: element.net,
                Name: element.name,
                ["X Coord"]: element.x,
                ["Y Coord"]: element.y,
                Side: tpLayer ? tpLayer.description : null,
                Type: tpType ? tpType.description : null,
                ["Hole Size"]: "",
              };
            }
          )
        : null;
    },
    guidePins() {
      return this.$store.getters["dutconfigs/dutConfigs"].guide_pins
        ? this.$store.getters["dutconfigs/dutConfigs"].guide_pins.map(
            (element) => {
              const gpLayer = this.guidepinLayers.find(
                (layer) => layer.pk == element.layer
              );
              return {
                Net: "",
                Name: element.name,
                ["X Coord"]: element.x,
                ["Y Coord"]: element.y,
                Side: gpLayer ? gpLayer.description : null,
                Type: "Locating Pin",
                ["Hole Size"]: "",
              };
            }
          )
        : null;
    },
    pressurePins() {
      return this.$store.getters["dutconfigs/dutConfigs"].pressure_pins
        ? this.$store.getters["dutconfigs/dutConfigs"].pressure_pins.map(
            (element) => ({
              Net: "",
              Name: element.name,
              ["X Coord"]: element.x,
              ["Y Coord"]: element.y,
              Side: "",
              Type: "Pressure Pin",
              ["Hole Size"]: "",
            })
          )
        : null;
    },
    dutConfigData() {
      return [].concat(this.testPoints, this.guidePins, this.pressurePins);
    },
    tpcbConfigId() {
      return Object.keys(this.project.tpcb_config_details).length > 0
        ? Object.keys(this.project.tpcb_config_details)[0]
        : null;
    },
  },
  methods: {
    csvExporter,
    ...mapActions({
      fetchQuote: "quotes/getQuote",
      fetchStatus: "quotes/getQuoteStatus",
      fetchDutDetails: "dutconfigs/getDutDetails",
      createDutConfig: "dutconfigs/saveDutConfigs",
      updateDutConfigs: "dutconfigs/updateDutConfigs",
      toggleLoading: "ui/loading",
    }),
    dateFormatter(date) {
      return new Date(date).toLocaleDateString();
    },
    deleteHandler() {
      this.stopDialog();
      this.$emit("delete", this.selected);
    },
    dutDeleteHandler() {
      this.stopDialog();
      this.$emit("deleteDut", {
        pk: this.selectedDut,
        initThumbnail: this.isDCCount == 1,
        project: this.project.pk,
      });
    },
    tpcbDeleteHandler() {
      this.stopDialog();
      this.$emit("deleteTPCB", this.tpcbConfigId);
    },
    async dutCSVHandler(dutId) {
      this.$store.dispatch("ui/loading", "Exporting");
      await this.$store.dispatch("dutconfigs/getTestPointOptions");
      await this.$store.dispatch("dutconfigs/getGuidePinOptions");
      await this.$store.dispatch("dutconfigs/getDutConfigsById", dutId);
      const dutObj = this.dutConfigsList.find((dut) => dut.pk == dutId);
      const fileName = `${dutObj.name}-fixture-conponent.csv`;
      this.csvExporter({ arrData: this.dutConfigData, fileName });
      this.$store.dispatch("ui/loading");
    },
    gotoProject({ link, newtab }) {
      if (newtab) {
        let routeData = this.$router.resolve({ path: link });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(link);
      }
    },
    gotoNextRoute({ pathName, pathId, newtab = false }) {
      if (newtab) {
        let routeData = this.$router.resolve({
          name: pathName,
          params: { id: pathId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: pathName,
          params: { id: pathId },
        });
      }
    },
    async gotoNextRouteCopyUrl({ pathName, pathId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: pathName,
          params: { id: pathId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    gotoProjectFlow({ pathName, pathId, activeTab = 0, newtab = false }) {
      if (newtab) {
        localStorage.setItem(
          "project",
          JSON.stringify({ active_tab: activeTab })
        );
        let routeData = this.$router.resolve({
          name: pathName,
          params: { id: pathId, activeTab },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: pathName,
          params: { id: pathId, activeTab },
        });
      }
    },
    async gotoProjectFlowCopyUrl({ pathName, pathId, activeTab = 0 }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: pathName,
          params: { id: pathId, activeTab },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    gotoConfigure({ pathName, projectId, dutId, newtab = false }) {
      this.$store.dispatch("projects/setProject", this.project);
      if (newtab) {
        let routeData = this.$router.resolve({
          name: pathName,
          params: { id: projectId, did: dutId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: pathName,
          params: { id: projectId, did: dutId },
        });
      }
    },
    async gotoConfigureCopyUrl({ pathName, projectId, dutId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: pathName,
          params: { id: projectId, did: dutId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    gotoSvgDrawing({ projectPk, fdId, dutId, newtab = false }) {
      if (newtab) {
        let routeData = this.$router.resolve({
          name: "svg-drawing",
          params: { id: projectPk, fdId, dutId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "svg-drawing",
          params: { id: projectPk, fdId, dutId },
        });
      }
    },
    async gotoSvgDrawingCopyUrl({ projectPk, fdId, dutId }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: "svg-drawing",
          params: { id: projectPk, fdId, dutId },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
    async onSaveTPCB(tpcbName) {
      this.stopDialog();
      this.$emit("saveTPCB", {
        projectId: this.project.pk,
        fdId: this.fixtureDesignId,
        tpcbName,
      });
    },
    async gotoQuotesLink() {
      if (this.quotePk) {
        this.$emit("onQuoteDetailsView", this.quotePk);
      } else {
        this.quoteDialog = true;
      }
    },
    async unlinkFromQuote() {
      console.log("unlink project");
      let formData = new FormData();
      formData.append("quote", "");
      this.$emit("saveQuote", {
        pk: this.fixtureDesignId,
        data: formData,
      });
    },
    showUnlinkDialog() {
      this.confirmAction = "unlinkProject";
      this.confirmMessage = `Are you sure you want to unlink project ${this.project.project_name} to quote?`;
      this.dialog = true;
    },
    showQuoteDialog(id) {
      this.confirmAction = "saveQuote";
      this.confirmMessage = `Are you sure to assign Quote ${id} to project ${this.project.project_name}?`;
      this.quoteId = id;
      this.dialog = true;
    },
    async onSaveQuote() {
      this.stopDialog();
      let formData = new FormData();
      formData.append("quote", this.quoteId);
      const response = await this.fetchDutDetails({
        params: { quote_id: this.quoteId },
      });
      await this.saveDdToDc(response);
      this.$emit("saveQuote", {
        pk: this.fixtureDesignId,
        data: formData,
      });
      this.quoteId = null;
      this.onCloseQuoteModal();
    },
    async saveDdToDc(dutDetails) {
      if (dutDetails.length == 0) {
        console.log("No DD saved");
        return;
      }
      if (dutDetails.length > this.isDCCount) {
        console.log("DD length is > DC length");
        for (let i = this.isDCCount; i < dutDetails.length; i++) {
          const response = await this.addNewDC({
            user: this.project.user,
            project: this.project.pk,
            fixture_design: Object.keys(this.project.fixture_design_details)[0],
            dut_name: dutDetails[i].dut_name,
            dut_details: dutDetails[i].id,
          });
          console.log("POST NEW DC ON LINK", response);
          this.$store.commit("projects/INSERT_DC_TO_LIST", response);
        }
      }
      for (let i = 0; i < dutDetails.length; i++) {
        let ddToDcData = new FormData();
        ddToDcData.append("dut_details", dutDetails[i].id);
        this.toggleLoading("One moment please, updating DUT Config");
        await this.updateDutConfigs({
          pk: Object.keys(this.project.dut_config_details)[i],
          data: ddToDcData,
        });
        this.toggleLoading();
        console.log("Created Link DD to DC");
      }
    },
    async addNewDC({ user, project, fixture_design, dut_name, dut_details }) {
      console.log("Added new DC");
      let dutData = new FormData();
      dutData.append("name", dut_name);
      dutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
      dutData.append("user", user);
      dutData.append("project", project);
      dutData.append("fixture_design", fixture_design);
      dutData.append("dut_details", dut_details);
      const response = await this.createDutConfig(dutData);
      return response;
    },
    onCloseQuoteModal() {
      this.quoteDialog = false;
    },
    onCloseQuoteDetailsModal() {
      this.quoteDetailsDialog = false;
    },
    async createNewDut({ newtab }) {
      console.log(Object.keys(this.project?.fixture_design_details)[0]);
      //Post to DC
      let dutData = new FormData();
      dutData.append("name", this.project.project_name);
      dutData.append("pcb_thickness", this.DEFAULT_PCB_THICKNESS);
      dutData.append("user", this.project.user);
      dutData.append("project", this.project.pk);
      dutData.append(
        "fixture_design",
        Object.keys(this.project?.fixture_design_details)[0]
      );
      const dut_response = await this.createDutConfig(dutData);
      //End Post to DC
      if (newtab) {
        let routeData = this.$router.resolve({
          name: "edit-dutconfig",
          params: { id: this.project.pk, did: dut_response.pk },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.replace({
          name: "edit-dutconfig",
          params: { id: this.project.pk, did: dut_response.pk },
        });
      }
    },
    getIndexSelectedDut() {
      return this.dutConfigsList.findIndex(
        (element) => element.pk === this.selectedDut
      );
    },
  },
};
</script>
